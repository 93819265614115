import { render, staticRenderFns } from "./brandsDropdown.vue?vue&type=template&id=ae706e4a&scoped=true&"
import script from "./brandsDropdown.vue?vue&type=script&lang=js&"
export * from "./brandsDropdown.vue?vue&type=script&lang=js&"
import style0 from "./brandsDropdown.vue?vue&type=style&index=0&id=ae706e4a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ae706e4a",
  null
  
)

export default component.exports